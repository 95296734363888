var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.items,
          fields: _vm.fields,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc
        },
        on: {
          "update:sortBy": function($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function($event) {
            _vm.sortDesc = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "cell(estado)",
            fn: function(data) {
              return [
                _c("b", { staticClass: "text-success" }, [
                  _vm._v(_vm._s(data.value))
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }