var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container__historico" },
    [
      _c(
        "b-container",
        [
          _c("Owner"),
          _c(
            "b-row",
            { staticClass: "start", attrs: { "align-v": "stretch" } },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "text-center custom-row-top",
                      attrs: { "align-v": "center" }
                    },
                    [
                      _vm.country === "MX"
                        ? _c("b-col", { attrs: { md: "1" } }, [
                            _c("img", {
                              attrs: {
                                src: require("../assets/images/mexico-flag.svg"),
                                width: "60",
                                alt: "country",
                                "align-self": "end"
                              }
                            })
                          ])
                        : _c("b-col", { attrs: { md: "1" } }, [
                            _c("img", {
                              attrs: {
                                src: require("../assets/images/eeuu-flag.svg"),
                                width: "60",
                                alt: "country",
                                "align-self": "end"
                              }
                            })
                          ]),
                      _c(
                        "b-col",
                        { staticClass: "country-group", attrs: { md: "4" } },
                        [
                          _c(
                            "div",
                            { staticClass: "subcard__container" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.getUserEmail,
                                    expression: "getUserEmail"
                                  }
                                ],
                                staticClass: "custom-input",
                                attrs: {
                                  type: "email",
                                  name: "email",
                                  autocomplete: "on",
                                  placeholder: this.$i18n.t("welcome.email")
                                },
                                domProps: { value: _vm.getUserEmail },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.getPatient("email")
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.getUserEmail = $event.target.value
                                  }
                                }
                              }),
                              !_vm.isSearchEmail
                                ? _c("b-icon", {
                                    staticClass: "input__search__icon",
                                    attrs: {
                                      icon: "search",
                                      "aria-hidden": "true",
                                      "flip-h": ""
                                    }
                                  })
                                : _c("b-spinner", {
                                    staticClass: "input__search__icon",
                                    attrs: { small: "", label: "Loading..." }
                                  })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "country-group", attrs: { md: "4" } },
                        [
                          _c(
                            "div",
                            { staticClass: "subcard__container" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.nhcNumber,
                                    expression: "nhcNumber"
                                  }
                                ],
                                staticClass: "custom-input",
                                attrs: {
                                  type: "number",
                                  name: "nhc",
                                  autocomplete: "on",
                                  placeholder: this.$i18n.t("welcome.nhc")
                                },
                                domProps: { value: _vm.nhcNumber },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.getPatient("nhc")
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.nhcNumber = $event.target.value
                                  }
                                }
                              }),
                              !_vm.isSearchNhc
                                ? _c("b-icon", {
                                    staticClass: "input__search__icon",
                                    attrs: {
                                      icon: "search",
                                      "aria-hidden": "true",
                                      "flip-h": ""
                                    }
                                  })
                                : _c("b-spinner", {
                                    staticClass: "input__search__icon",
                                    attrs: { small: "", label: "Loading..." }
                                  })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "menu__user",
                          attrs: { "offset-xl": "1", md: "3", xl: "2" }
                        },
                        [_c("MenuUser")],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "align-items-center mb-4" },
            [
              _c("b-col", { attrs: { cols: "4", xl: "6" } }, [
                _c("div", [
                  _c("h3", { staticClass: "title-page" }, [
                    _vm._v(_vm._s(_vm.$t("history.Title")))
                  ])
                ])
              ]),
              _c(
                "b-col",
                {
                  staticClass: "buttons-custom",
                  attrs: { cols: "8", xl: "6" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          ref: "dropdown",
                          staticClass: "m-2",
                          attrs: {
                            id: "dropdown-pdf",
                            "menu-class": "form-user",
                            variant: "link",
                            "toggle-class": "text-decoration-none",
                            "no-caret": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-blue-dark ml-0",
                                      attrs: { variant: "outline-primary" }
                                    },
                                    [
                                      _vm._v(" A "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("history.pdf.view"))
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-dropdown-text",
                            { staticClass: "my-3 text-header-user" },
                            [
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("history.pdf.recent")))
                              ])
                            ]
                          ),
                          _c("b-dropdown-divider"),
                          _vm.isLoadingPdf
                            ? [
                                _c(
                                  "b-row",
                                  {
                                    staticClass: "justify-content-center mt-2"
                                  },
                                  [
                                    _c("b-spinner", {
                                      attrs: { label: "Loading..." }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : [
                                _vm._l(_vm.dataPdf, function(item, index) {
                                  return _c(
                                    "b-dropdown-item",
                                    {
                                      key: index,
                                      staticClass: "d-flex",
                                      on: {
                                        click: function($event) {
                                          return _vm.pdfOpen(item)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "justify-content-between"
                                        },
                                        [
                                          _c("p", [
                                            _c("b", [
                                              _vm._v(
                                                "PDF #" + _vm._s(index + 1)
                                              )
                                            ])
                                          ]),
                                          _c("img", {
                                            attrs: {
                                              src: require("../assets/images/view.svg"),
                                              alt: "view"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                !_vm.dataPdf.length
                                  ? _c("p", { staticClass: "ml-3" }, [
                                      _c("b", [_vm._v("No PDFs")])
                                    ])
                                  : _vm._e()
                              ]
                        ],
                        2
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-blue-dark",
                          attrs: { to: "/proposal" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("history.Proposal")) + " + "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "text-center custom-row",
              attrs: { "align-v": "center" }
            },
            [
              _c("b-col", [
                _c("img", {
                  attrs: {
                    width: "60",
                    src: require("../assets/images/profile-img.png")
                  }
                })
              ]),
              _c("b-col", [
                _c("p", [_c("b", [_vm._v(_vm._s(_vm.namePatient))])])
              ]),
              _c("b-col", [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("history.Patient")) + " ID: "),
                  _c("span", [_vm._v(_vm._s(_vm.NHC))])
                ])
              ]),
              _c("b-col", [
                _c("p", [
                  _vm._v(" " + _vm._s(_vm.$t("history.Gender")) + ": "),
                  _c("span", [_vm._v(_vm._s(_vm.$t(_vm.gender)))])
                ])
              ]),
              _vm.country === "MX"
                ? _c("b-col", [
                    _c("img", {
                      attrs: {
                        src: require("../assets/images/mexico-flag.svg"),
                        width: "60",
                        alt: "country"
                      }
                    })
                  ])
                : _c("b-col", [
                    _c("img", {
                      attrs: {
                        src: require("../assets/images/eeuu-flag.svg"),
                        width: "60",
                        alt: "country"
                      }
                    })
                  ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "custom-row-table text-center" },
            [_c("TableMain", { attrs: { fillTableData: _vm.fillTableData } })],
            1
          ),
          _c("h5", [_vm._v("Cotizaciones enviadas a central")]),
          _c(
            "div",
            { staticClass: "custom-row-table text-center" },
            [
              _c("TableMain2", {
                attrs: { fillTableData: _vm.fillTableDataCentral }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }