<template>
  <div>
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #cell(estado)="data">
        <b class="text-success">{{ data.value }}</b>
      </template>

      <template #cell(detalles)="row">
        <b-button
          size="sm"
          @click="info(row.item, row.index, $event.target)"
          class="mr-1"
        >
          {{ $t("history.table.See") }}
        </b-button>
      </template>
    </b-table>
    <!-- Info modal -->
    <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      size="lg"
      ok-only
      @hide="resetInfoModal"
    >
      <h4>{{ $t("history.table.detailBusiness.Detail") }}:</h4>
      <p>
        <b> {{ $t("history.table.detailBusiness.Price") }} MXP:</b>
        {{
          infoModal.content.detalleBase &&
          infoModal.content.detalleBase.ValorMXP
        }}
      </p>
      <p>
        <b> {{ $t("history.table.detailBusiness.Price") }} USD: </b>
        {{
          infoModal.content.detalleBase &&
          infoModal.content.detalleBase.ValorUSD
        }}
      </p>
      <p>
        <b> {{ $t("history.table.detailBusiness.Created") }}:</b>
        {{
          infoModal.content.detalleBase &&
          infoModal.content.detalleBase.creacionCotizacion
        }}
      </p>
      <hr />
      <h4>{{ $t("history.table.detailBusiness.Business") }}</h4>

      <b-table
        :items="infoModal.content.detalleNegocio"
        :fields="fieldsDetails"
      >
        <template #cell(CreacionNegocio)="row">
          {{ new Date(row.item.CreacionNegocio).toLocaleString() }}
        </template>
        <template #cell(Pais)="row">
          {{ row.item.Pais.toUpperCase() }}
        </template>
        <template #cell(Valor)="row">
          <template v-if="row.item.Pais === 'mexico'">MXN </template>
          <template v-else>USD </template>
          {{ formatCurrency(row.item.Valor) }}
        </template>
        <template #cell(Productos)="row">
          <b-button size="sm" class="button-details" @click="row.toggleDetails">
            <b-icon
              icon="chevron-up"
              aria-hidden="true"
              v-if="row.detailsShowing"
            ></b-icon>
            <b-icon icon="chevron-down" aria-hidden="true" v-else></b-icon>
          </b-button>
        </template>
        <template #row-details="row">
          <h5>{{ $t("history.table.detailBusiness.Articles") }}</h5>

          <b-table :items="row.item.Productos" :fields="fieldsArticle">
            <template #cell(PrecioLista)="row">
              <template v-if="row.item.monedaPrecio === 'Dolares'"
                >USD</template
              >
              <template v-else>MXN</template>
              {{ formatCurrency(row.item.PrecioLista) }}
            </template>
          </b-table>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { API_ROOT } from "../../Constants/Api";
import moment from "moment";
import { NumberFormat } from "../../helpers/NumberFormat";
import { infoModal } from "../../Constants/InfoModal";
import { ApiRequest } from '../../helpers/ApiRequest';
import { AlertForm, ICON } from '../../helpers/Alert';

export default {
  name: "TableMain",
  props: ["fillTableData"],
  data() {
    return {
      infoModal: infoModal,
      sortDesc: true,
      sortBy: "numero_de_cotizacion",
      country: "",
    };
  },
  mounted() {
    this.country = sessionStorage.getItem("country");
  },
  computed: {
    items() {
      return this.fillTableData.map((item) => {
        return {
          isActive: true,
          numero_de_cotizacion: item.Numero,
          fecha: new Date(item.createdAt).toLocaleDateString(),
          valor: `$ ${this.formatCurrency(
            this.country === "MX" ? item.ValorMXP : item.ValorUSD
          )}`,
          estado: this.translateCol("entregada"),
        };
      });
    },
    fields() {
      return [
        {
          key: "numero_de_cotizacion",
          label: this.translateCol("Quote"),
          sortable: true,
        },
        {
          key: "fecha",
          label: this.translateCol("Date"),
          sortable: true,
        },
        {
          key: "valor",
          label: `${this.translateCol("Price")} ${
            this.country === "MX" ? "(MXN)" : "(USD)"
          }`,
          sortable: true,
        },
        {
          key: "estado",
          label: this.translateCol("Status"),
        },
        {
          key: "detalles",
          label: this.translateCol("Details"),
        },
      ];
    },
    fieldsDetails() {
      return [
        {
          key: "Categoria",
          label: this.translateCol("detailBusiness.Category"),
        },
        {
          key: "CreacionNegocio",
          label: this.translateCol("detailBusiness.BusinessCreated"),
        },
        {
          key: "Pais",
          label: this.translateCol("detailBusiness.Country"),
        },
        {
          key: "Valor",
          label: this.translateCol("detailBusiness.Price"),
        },
        {
          key: "Productos",
          label: this.translateCol("detailBusiness.Products"),
        },
      ];
    },
    fieldsArticle() {
      return [
        {
          key: "Articulo",
          label: this.translateCol("detailBusiness.Article"),
        },
        {
          key: "CodigoAlterno",
          label: this.translateCol("detailBusiness.Code"),
        },
        {
          key: "PrecioLista",
          label: this.translateCol("detailBusiness.Price"),
        },
      ];
    },
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("de-DE").format(value);
    },
    async detalleAPI(id) {
      try {
        let data = await ApiRequest.get(`${API_ROOT}cotizacion/detalle/${id}`);
        data.detalleBaseCotizacion.creacionCotizacion = moment(
          data.detalleBaseCotizacion.creacionCotizacion
        ).format("LLL");
        data.detalleBaseCotizacion.ValorMXP = NumberFormat(
          data.detalleBaseCotizacion.ValorMXP
        );
        data.detalleBaseCotizacion.ValorUSD = NumberFormat(
          data.detalleBaseCotizacion.ValorUSD
        );

        const info = {
          detalleBase: data.detalleBaseCotizacion,
          detalleNegocio: data.detalleNegocio,
        };
        return info;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        return false;
      }
    },
    async info(item, index, button) {
      const info = await this.detalleAPI(item.numero_de_cotizacion);
      if(!info) return;
      this.infoModal.title = `${this.$i18n.t(
        "history.table.detailBusiness.Quote"
      )}: ${item.numero_de_cotizacion}`;
      this.infoModal.content = info;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    translateCol(colName) {
      return this.$i18n.t("history.table." + colName);
    },
  },
};
</script>

<style>
</style>