<template>
  <div class="container__historico">
    <b-container>
      <Owner />
      <b-row class="start" align-v="stretch">
        <b-col>
          <b-row class="text-center custom-row-top" align-v="center">
            <b-col md="1" v-if="country === 'MX'">
              <img
                src="../assets/images/mexico-flag.svg"
                width="60"
                alt="country"
                align-self="end"
              />
            </b-col>
            <b-col md="1" v-else>
              <img src="../assets/images/eeuu-flag.svg" width="60" alt="country" align-self="end" />
            </b-col>
            <b-col md="4" class="country-group">
              <div class="subcard__container">
                <input
                  v-on:keyup.enter="getPatient('email')"
                  v-model="getUserEmail"
                  type="email"
                  name="email"
                  class="custom-input"
                  autocomplete="on"
                  :placeholder="this.$i18n.t('welcome.email')"
                />
                <b-icon
                  icon="search"
                  aria-hidden="true"
                  flip-h
                  class="input__search__icon"
                  v-if="!isSearchEmail"
                ></b-icon>
                <b-spinner small label="Loading..." class="input__search__icon" v-else></b-spinner>
              </div>
            </b-col>
            <b-col md="4" class="country-group">
              <div class="subcard__container">
                <input
                  v-on:keyup.enter="getPatient('nhc')"
                  v-model="nhcNumber"
                  type="number"
                  name="nhc"
                  class="custom-input"
                  autocomplete="on"
                  :placeholder="this.$i18n.t('welcome.nhc')"
                />
                <b-icon
                  icon="search"
                  aria-hidden="true"
                  flip-h
                  class="input__search__icon"
                  v-if="!isSearchNhc"
                ></b-icon>
                <b-spinner small label="Loading..." class="input__search__icon" v-else></b-spinner>
              </div>
            </b-col>

            <b-col offset-xl="1" md="3" xl="2" class="menu__user">
              <MenuUser />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="align-items-center mb-4">
        <b-col cols="4" xl="6">
          <div>
            <h3 class="title-page">{{ $t("history.Title") }}</h3>
          </div>
        </b-col>
        <b-col class="buttons-custom" cols="8" xl="6">
          <div class="mt-4">
            <b-dropdown
              id="dropdown-pdf"
              menu-class="form-user"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              ref="dropdown"
              class="m-2"
            >
              <template #button-content>
                <b-button variant="outline-primary" class="btn-blue-dark ml-0">
                  A
                  <span>{{ $t("history.pdf.view") }}</span>
                </b-button>
              </template>
              <b-dropdown-text class="my-3 text-header-user">
                <b>{{ $t("history.pdf.recent") }}</b>
              </b-dropdown-text>
              <b-dropdown-divider></b-dropdown-divider>
              <template v-if="isLoadingPdf">
                <b-row class="justify-content-center mt-2">
                  <b-spinner label="Loading..."></b-spinner>
                </b-row>
              </template>
              <template v-else>
                <b-dropdown-item
                  v-for="(item, index) in dataPdf"
                  :key="index"
                  @click="pdfOpen(item)"
                  class="d-flex"
                >
                  <b-row class="justify-content-between">
                    <p>
                      <b>PDF #{{ index + 1 }}</b>
                    </p>
                    <img src="../assets/images/view.svg" alt="view" />
                  </b-row>
                </b-dropdown-item>
                <p class="ml-3" v-if="!dataPdf.length"><b>No PDFs</b></p>
              </template>
            </b-dropdown>
            <router-link class="btn btn-blue-dark" to="/proposal">
              {{ $t("history.Proposal") }} +
            </router-link>
          </div>
        </b-col>
      </b-row>
      <b-row class="text-center custom-row" align-v="center">
        <b-col>
          <img width="60" src="../assets/images/profile-img.png" />
        </b-col>
        <b-col>
          <p>
            <b>{{ namePatient }}</b>
          </p>
        </b-col>
        <b-col>
          <p>
            {{ $t("history.Patient") }} ID:
            <span>{{ NHC }}</span>
          </p>
        </b-col>
        <b-col>
          <p>
            {{ $t("history.Gender") }}:
            <span>{{ $t(gender) }}</span>
          </p>
        </b-col>
        <b-col v-if="country === 'MX'">
          <img src="../assets/images/mexico-flag.svg" width="60" alt="country" />
        </b-col>
        <b-col v-else>
          <img src="../assets/images/eeuu-flag.svg" width="60" alt="country" />
        </b-col>
      </b-row>
      <div class="custom-row-table text-center">
        <TableMain :fillTableData="fillTableData" />
      </div>
      <h5>Cotizaciones enviadas a central</h5>
      <div class="custom-row-table text-center">
        <TableMain2 :fillTableData="fillTableDataCentral" />
      </div>
    </b-container>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Owner from "@/components/Owner";
// import { API_HUBSPOT, API_ROOT, API_CENTRAL } from '../Constants/Api';
import { API_ROOT, API_CENTRAL } from "../Constants/Api";
import MenuUser from "../components/MenuUser.vue";
//import { dataURItoBlob } from '../helpers/dataBlob';
import TableMain from "@/components/History/TableMain";
import TableMain2 from "@/components/History/TableMain2";
import { sessionStoragePatient } from "../data/localStoragePatient";
import { getPatientAPI } from "../helpers/getPatientAPI";
import { AlertForm, ICON } from "../helpers/Alert";
import { ApiRequest } from "../helpers/ApiRequest";

export default {
  name: "Historico",
  data() {
    return {
      namePatient: "",
      imageUser: "",
      gender: "",
      NHC: "",
      owner: sessionStorage.getItem("ownerId"),
      fillTableData: [],
      fillTableDataCentral: [],
      country: "",
      getUserEmail: "",
      nhcNumber: "",
      isSearchEmail: false,
      isSearchNhc: false,
      dataPdf: [],
      isLoadingPdf: false,
      configRol: {},
    };
  },

  components: {
    Owner,
    MenuUser,
    TableMain,
    TableMain2,
  },
  created() {
    this.configRol = this.$store.state.configRol;
    if (this.configRol.history?.ventana === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(
        `${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("menu.History")}`,
        this.$i18n.t("permissions.validateAdmin"),
        ICON.WARNING,
        "center",
        null
      );
    }
  },
  mounted() {
    this.country = sessionStorage.getItem("country");

    if (sessionStorage.getItem("genero")) {
      this.gender = `history.${sessionStorage.getItem("genero").toLowerCase()}`;
    }
    this.NHC = sessionStorage.getItem("nhc");
    this.namePatient = `${sessionStorage.getItem("nombre")} ${sessionStorage.getItem("apellido")}`;
    this.getSucursales();
    this.dataTableQuotation();
    this.dataTableQuotationCentral();
    this.infoDataPdf();
  },
  methods: {
    pdfOpen(data) {
      /*
      const blob = dataURItoBlob(data);
      const url = URL.createObjectURL(blob);
      // to open the PDF in a new window
      window.open(url, '_blank');
      */
      // const blob = dataURItoBlob(data);
      // const url = URL.createObjectURL(blob);
      // to open the PDF in a new window
      window.open(data, "_blank");
    },
    async dataTableQuotationCentral() {
      if (!sessionStorage.getItem("vid")) return;
      try {
        var myHeadersT = new Headers();
        myHeadersT.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencodedT = new URLSearchParams();
        urlencodedT.append("key", "597633da-228f-10bd-cb29-b634ae8539aa");
        var requestOptionsT = {
          method: "POST",
          headers: myHeadersT,
          body: urlencodedT,
          redirect: "follow",
        };
        fetch(`${API_CENTRAL}validateUser`, requestOptionsT)
          .then((response) => response.text())
          .then((resultT) => {
            var myHeaders = new Headers();
            myHeaders.append("authorization", JSON.parse(resultT).token);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            //Declaramos los parametros de envio
            var dataNuevo = new URLSearchParams();
            dataNuevo.append("nhc", this.NHC);
            dataNuevo.append("localidad", this.country === "US" ? 2 : 1);
            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: dataNuevo,
              redirect: "follow",
            };
            const cotizaciones = [];
            fetch(`${API_CENTRAL}apipost/listadoNegociosCentral`, requestOptions)
              .then((response) => response.text())
              .then((res) => {
                try {
                  let _data = JSON.parse(res);
                  _data.addenda.forEach((item) => {
                    const _item = {
                      Estado: item.Estado,
                      Negocios: [],
                      Numero: item.IdSolicitudIM,
                      ValorMXP: item.ValorMXP,
                      ValorUSD: item.ValorUSD,
                      Vid: 6964,
                      createdAt: item.createdAt,
                      updatedAt: item.updatedAt,
                      _id: "63a4bdc7d1daacbd6a99d1c7",
                    };

                    //Metemos los sub-elementos de la nota

                    //Declaramos los parametros de envio
                    var dataDetalle = new URLSearchParams();
                    dataDetalle.append("IdSolicitudIM", item.IdSolicitudIM.toString());
                    var requestOptions = {
                      method: "POST",
                      headers: myHeaders,
                      body: dataDetalle,
                      redirect: "follow",
                    };
                    fetch(`${API_CENTRAL}apipost/listadoNegocioDetalleCentral`, requestOptions)
                      .then((response2) => response2.text())
                      .then((res2) => {
                        try {
                          let response = res2;
                          if (response == null) {
                            console.log(JSON.parse(response).valido);
                          }
                          _item.Negocios.push()
                        } catch (error) {
                          AlertForm(
                            `Error Code: ${JSON.parse(res).valido}`,
                            JSON.parse(res).mensaje,
                            ICON.ERROR
                          );
                        }
                      })
                      .catch((error) => {
                        if (error !== "Data Failure") {
                          AlertForm(`Error Code: ${error}`, error, ICON.ERROR);
                        } else {
                          console.log("sin error");
                        }
                      });

                    cotizaciones.push(_item);
                  });
                  this.fillTableDataCentral = cotizaciones;
                } catch (error) {
                  AlertForm(
                    `Error Code: ${JSON.parse(res).valido}`,
                    JSON.parse(res).mensaje,
                    ICON.ERROR
                  );
                }
              })
              .catch((error) => {
                if (error !== "Data Failure") {
                  AlertForm(`Error Code: ${error}`, error, ICON.ERROR);
                } else {
                  console.log("sin error");
                }
              });
          })
          .catch((error) => console.log("error", error));

        //data.cotizaciones;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async dataTableQuotation() {
      if (!sessionStorage.getItem("vid")) return;
      try {
        let data = await ApiRequest.get(
          `${API_ROOT}cotizacion/vid/${sessionStorage.getItem("vid")}`
        );
        this.fillTableData = data.cotizaciones;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    async infoDataPdf() {
      if (!this.NHC) return;
      this.isLoadingPdf = true;
      this.dataPdf = [];
      try {
        let data = await ApiRequest.get(`${API_ROOT}uploadFile/obtenerPdfNhc/nhc/${this.NHC}`);
        this.dataPdf = data.cadenas;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      } finally {
        this.isLoadingPdf = false;
      }
    },
    async getPatient(item) {
      await getPatientAPI(item, this.dataLocal, this);
    },

    dataLocal(userInfo) {
      if (userInfo) {
        if (userInfo.sucursal_defecto) {
          let origen = "",
            destino = "";
          if (this.country === "US") {
            let { sucursal_defecto_origen, sucursal_defecto_destino } = userInfo.sucursal_defecto;
            origen = sucursal_defecto_origen;
            destino = sucursal_defecto_destino;
          } else {
            let { sucursal_mexico, sucursal_defecto_destino } = userInfo.sucursal_defecto;
            origen = sucursal_mexico;
            destino = sucursal_defecto_destino;
          }
          const sucursales = [...this.sucursalesUS, ...this.sucursalesMX];
          const sucursalOrigen = sucursales.find((el) => el.name === origen);
          sessionStorage.setItem(
            "sedeOrigen",
            JSON.stringify({
              name: sucursalOrigen.name,
              value: sucursalOrigen.name,
              country: sucursalOrigen.country,
            })
          );
          sessionStorage.setItem("sedeDestinoKey", destino || "");
        }
        sessionStoragePatient(userInfo, this.country);
      }
      this.gender = `history.${sessionStorage.getItem("genero").toLowerCase()}`;
      this.NHC = sessionStorage.getItem("nhc");
      this.namePatient = `${sessionStorage.getItem("nombre")} ${sessionStorage.getItem(
        "apellido"
      )}`;
      this.dataTableQuotation();
      this.infoDataPdf();
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      Toast.fire({
        icon: "success",
        title: "Patient Found",
      });
    },

    async getSucursales() {
      try {
        const sucursales = JSON.parse(localStorage.getItem("sucursales"));

        this.sucursalesUS = sucursales.filter((el) => el.country === "us");
        this.sucursalesMX = sucursales.filter((el) => el.country === "mx");
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/css/global.css";

/** CONTAINER */
.container__historico {
  background-color: #f6f6f6;
  height: 100vh;
  width: 95%;
}
/** GLOBAL */
p {
  font-size: 14px;
}

/** INPUT SEARCH EMAIL & NHC */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/** MENU */

.custom-row-top {
  background-color: #fff;
  padding: 10px;
  border-radius: 20px;
  flex-wrap: nowrap;
  margin-top: 20px;
}

.menu__user {
  display: flex;
  justify-content: flex-end;
}

/** TITLE MAIN */
.title-page {
  color: #707070;
  text-align: left;
  align-self: center;
}

/** INFO USER */
.custom-row {
  background-color: #fff;
  padding: 20px 0px;
  border-radius: 16px;
}

/** MAIN TABLE */
.custom-row-table {
  background-color: #fff;
  padding-bottom: 20px;
  margin: 40px 0;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
}

/** CUSTOM BUTTONS */
.buttons-custom {
  text-align: end;
}

/** DROPDOWN CUSTOM */
.buttons-custom /deep/ .form-user {
  width: 200px;
}

.text-header-user {
  font-weight: bold;
  color: #b4afaf;
}
</style>
