var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.items,
          fields: _vm.fields,
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc
        },
        on: {
          "update:sortBy": function($event) {
            _vm.sortBy = $event
          },
          "update:sort-by": function($event) {
            _vm.sortBy = $event
          },
          "update:sortDesc": function($event) {
            _vm.sortDesc = $event
          },
          "update:sort-desc": function($event) {
            _vm.sortDesc = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "cell(estado)",
            fn: function(data) {
              return [
                _c("b", { staticClass: "text-success" }, [
                  _vm._v(_vm._s(data.value))
                ])
              ]
            }
          },
          {
            key: "cell(detalles)",
            fn: function(row) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "mr-1",
                    attrs: { size: "sm" },
                    on: {
                      click: function($event) {
                        return _vm.info(row.item, row.index, $event.target)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("history.table.See")) + " ")]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "b-modal",
        {
          attrs: {
            id: _vm.infoModal.id,
            title: _vm.infoModal.title,
            size: "lg",
            "ok-only": ""
          },
          on: { hide: _vm.resetInfoModal }
        },
        [
          _c("h4", [
            _vm._v(_vm._s(_vm.$t("history.table.detailBusiness.Detail")) + ":")
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("history.table.detailBusiness.Price")) +
                  " MXP:"
              )
            ]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.infoModal.content.detalleBase &&
                    _vm.infoModal.content.detalleBase.ValorMXP
                ) +
                " "
            )
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("history.table.detailBusiness.Price")) +
                  " USD: "
              )
            ]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.infoModal.content.detalleBase &&
                    _vm.infoModal.content.detalleBase.ValorUSD
                ) +
                " "
            )
          ]),
          _c("p", [
            _c("b", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("history.table.detailBusiness.Created")) +
                  ":"
              )
            ]),
            _vm._v(
              " " +
                _vm._s(
                  _vm.infoModal.content.detalleBase &&
                    _vm.infoModal.content.detalleBase.creacionCotizacion
                ) +
                " "
            )
          ]),
          _c("hr"),
          _c("h4", [
            _vm._v(_vm._s(_vm.$t("history.table.detailBusiness.Business")))
          ]),
          _c("b-table", {
            attrs: {
              items: _vm.infoModal.content.detalleNegocio,
              fields: _vm.fieldsDetails
            },
            scopedSlots: _vm._u([
              {
                key: "cell(CreacionNegocio)",
                fn: function(row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(row.item.CreacionNegocio).toLocaleString()
                        ) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "cell(Pais)",
                fn: function(row) {
                  return [
                    _vm._v(" " + _vm._s(row.item.Pais.toUpperCase()) + " ")
                  ]
                }
              },
              {
                key: "cell(Valor)",
                fn: function(row) {
                  return [
                    row.item.Pais === "mexico"
                      ? [_vm._v("MXN ")]
                      : [_vm._v("USD ")],
                    _vm._v(
                      " " + _vm._s(_vm.formatCurrency(row.item.Valor)) + " "
                    )
                  ]
                }
              },
              {
                key: "cell(Productos)",
                fn: function(row) {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "button-details",
                        attrs: { size: "sm" },
                        on: { click: row.toggleDetails }
                      },
                      [
                        row.detailsShowing
                          ? _c("b-icon", {
                              attrs: {
                                icon: "chevron-up",
                                "aria-hidden": "true"
                              }
                            })
                          : _c("b-icon", {
                              attrs: {
                                icon: "chevron-down",
                                "aria-hidden": "true"
                              }
                            })
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "row-details",
                fn: function(row) {
                  return [
                    _c("h5", [
                      _vm._v(
                        _vm._s(_vm.$t("history.table.detailBusiness.Articles"))
                      )
                    ]),
                    _c("b-table", {
                      attrs: {
                        items: row.item.Productos,
                        fields: _vm.fieldsArticle
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(PrecioLista)",
                            fn: function(row) {
                              return [
                                row.item.monedaPrecio === "Dolares"
                                  ? [_vm._v("USD")]
                                  : [_vm._v("MXN")],
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCurrency(row.item.PrecioLista)
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }